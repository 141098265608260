@import 'styles/colors';

.avatar-cropper {
  width: 690px;
  display: flex;
  flex-direction: column;

  .replace-btn {
    background-color: transparent;
    font-family: inherit;
    border: 1px solid $primary;
    cursor: pointer;
    line-height: 1;
    border-radius: 3px;
    padding: 12px 15px;
    color: $primary;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.25s;

    &:hover {
      color: white;
      background-color: $primary;
    }
  }

  .file-input {
    display: none;
  }

  .modal-title {
    font-size: 20px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    .remove-btn {
      margin-right: auto;
    }
  }

  .edit-panel {
    position: relative;
    border-top: 1px solid $black;
    height: 80px;
    background-color: black;
    color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .wrapper {
    position: relative;
  }

  .rotate-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 1;
  }

  .image-cropper {
    width: 100%;
    height: 360px;
    background-color: $black;

    &.view-mode {
      pointer-events: none;
    }
  }
}

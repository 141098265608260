@import 'styles/colors';

.date-time-picker {
  display: flex;
  position: relative;

  &.disabled {
    &::after {
      content: '';
      width: 100%;
      position: absolute;
      height: 100%;
      cursor: not-allowed;
      background-color: rgba($gray95, 0.4);
    }
  }

  .time-picker {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

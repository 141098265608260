.notes-modal {
  min-width: 250px;
  max-width: 550px;

  .header {
    padding: 5px 5px 0;
    display: flex;
    justify-content: flex-end;
  }

  .close-icon {
    padding: 6px;

    &:hover {
      background-color: inherit;
    }
  }

  .content {
    padding: 0 10px 15px;
    white-space: break-spaces;
    max-height: 500px;
    overflow: auto;
  }

  .note-target-name {
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 5px;
  }
}

.multi-notes-column {
  display: flex;

  .first-note {
    display: inline-block;
  }

  .count {
    margin-left: 5px;
    color: var(--color-silver-chalice);
  }

  &:hover {
    color: var(--color-primary);
    cursor: pointer;

    .first-note {
    }

    .count {
      color: var(--color-primary);
    }
  }
}

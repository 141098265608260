.input-wrapper {
  display: inline-flex;

  &.right {
    flex-direction: row-reverse;
    justify-content: flex-end;

    .icon-wrapper {
      border-left: none;
      border-right: 2px solid var(--color-gray-light);
      border-radius: 0 3px 3px 0;
    }

    .labeled-input {
      border-radius: 3px 0 0 3px;
    }
  }

  &.disabled {
    transition: background-color 0.25s ease-in-out;
  }

  &.disabled:hover {
    cursor: not-allowed;
    background-color: var(--color-concrete);
  }

  .icon-wrapper {
    background-color: var(--color-concrete);
    padding: 0 7px;
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 40px;
    border: 2px solid var(--color-gray-light);
    border-right: none;
    justify-content: center;
    color: var(--color-gray);
    border-radius: 3px 0 0 3px;
    font-weight: 600;
  }

  .labeled-input {
    flex: 1;
    border-radius: 0 3px 3px 0;

    &.right {
      text-align: right;
    }
  }
}

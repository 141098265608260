.trucking-route {
  font-size: 13px;
  position: relative;
  min-height: 80px;
  display: flex;
  border: 1px solid var(--color-alto);
  border-radius: 5px;
  align-items: center;

  &.is-vertical {
    flex-direction: column;

    .route {
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .arrow {
      margin: 0;
      transform: rotate(90deg);
    }
  }

  @media screen and (max-width: 48em) {
    flex-wrap: wrap;
  }

  .route {
    color: var(--color-black);
    padding: 15px;
    flex: 1;
    align-self: flex-start;
  }

  .arrow {
    margin-left: auto;
    margin-right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: var(--color-primary);
  }
}

@import 'styles/colors';

.dropdown-list {
  position: absolute;
  z-index: 1;
  top: 100%;
  margin-top: 10px;
  left: 0;
  transition: opacity 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0 -1px 12px 0;
  background-color: white;
  overflow-y: auto;
  max-height: 320px;
  min-width: 170px;

  &.is-opened-left {
    left: auto;
    right: 0;
  }

  .item-search-box {
    min-height: 40px;
    border: none;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid $light-gray;
    font-weight: normal;
  }

  .item-search {
    border: none;

    &:focus {
      box-shadow: none;
    }

    :global {
      .SearchBox input[type='search'] {
        font-size: 13px;
      }
    }
  }

  .list-item {
    min-height: 40px;
    background-color: white;
    display: flex;
    align-items: center;
    transition: background-color 0.3s, color 0.3s ease;
    border-bottom: 1px solid $light-gray;
    color: $black;
    font-weight: 400;
    white-space: nowrap;

    .option-label {
      flex: 1;
      padding: 0 10px;
    }

    &:hover {
      background-color: $light-orange;
      color: $primary;
    }

    &.active {
      background-color: $primary;
      color: white;
    }

    &.not-available {
      background-color: $light-orange;
      color: $dark-gray;
      cursor: not-allowed;
    }

    &:last-child {
      border-bottom: none;
    }

    .option-icon {
      margin-left: 10px;
    }

    .selected-icon {
      margin-right: 10px;
    }

    .option-icon,
    .selected-icon {
      width: 15px;
    }
  }

  .no-filters-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    color: $black;
    text-align: center;
  }

  .info-icon {
    color: $dark-gray;
    width: 25px;
    margin-bottom: 10px;
  }
}

@import 'styles/colors';

.tooltip-wrapper {
  position: absolute;
  left: 50%;
  top: -25%;
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translate(-50%, -80%);
  animation: slide-in-bottom 2.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  .tooltip {
    width: 70%;
    color: white;
    text-align: center;
    border-radius: 8px;
    background-color: $primary-dark-blue;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.2);
    padding: 10px 15px;

    .corner {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 6px 0 6px;
      border-color: $primary-dark-blue transparent transparent transparent;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translate(-50%, -75%);
    opacity: 0;
  }
  15% {
    transform: translate(-50%, -100%);
    opacity: 1;
  }
  85% {
    transform: translate(-50%, -100%);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -75%);
    opacity: 0;
  }
}

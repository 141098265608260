@import 'styles/colors';

.desc {
  font-size: 13px;
  color: $dark-gray;
}

.radio-content {
  margin-left: 40px;

  .icon {
    width: 20px;
    height: 20px;
    color: $primary;
  }

  .input-btn {
    color: $primary;
    cursor: pointer;
    min-width: 140px;
    padding-left: 10px;
  }

  .input-content {
    align-items: center;
    justify-content: space-between;
    display: flex;
  }

  .input-label {
    margin-bottom: 5px;
    font-weight: 400;
  }
}

.placeholder {
  display: flex;
  align-items: center;
}

@import 'styles/colors';

.truck-badge {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $dark-gray;

  &.active {
    background-color: $primary;
  }

  .icon {
    color: white;
    width: 15px;
  }
}

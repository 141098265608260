.checkbox-wrapper {
  display: flex;

  &.is-disabled {
    opacity: 0.8;
    cursor: not-allowed;

    .checkbox {
      cursor: not-allowed;
    }
  }
}

.checkbox {
  position: relative;
  display: flex;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 2px solid var(--color-alto);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  user-select: none;

  &.is-checked {
    border-color: transparent;
  }

  .check-icon {
    color: var(--color-primary);
    position: absolute;
    height: calc(100% + 4px);
  }
}

.label-wrapper {
  font-size: 14px;
  margin-left: 10px;

  .label {
    font-weight: 600;
    margin-bottom: 5px;
  }

  .description {
    margin: 0;
    font-size: 13px;
  }
}

@import 'styles/colors';

.row {
  display: flex;
  align-items: center;

  .label {
    width: 40%;
    font-weight: 600;
  }

  .value {
    display: flex;
    align-items: center;
    flex: 1;
    height: 40px;
    transition: background-color 0.25s ease-in-out;

    &.has-paddings {
      padding: 0 7px;
    }

    &.is-hovered:hover {
      background-color: $gray95;
    }
  }
}

@import 'styles/colors';

.input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 2px solid $light-gray;
  font-size: 14px;
  color: $black;
  background-color: white;
  border-radius: 3px;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out, background-color 0.2s;
  box-shadow: none;
  -webkit-appearance: none;

  &.floatRight {
    text-align: right;
  }

  &.bolded {
    font-weight: 600;
  }

  &.large {
    height: 55px;
    font-size: 16px;
    font-weight: 600;
    border: 2px solid $medium-gray;
  }

  &:focus,
  &.has-error {
    border: 2px solid $primary;
    background-color: white;
    outline: none;
    box-shadow: 0 0 4px #ff8664;
  }

  &:disabled,
  &[readOnly] {
    background-color: transparent;
    transition: background-color 0.25s ease-in-out;
  }

  &::placeholder {
    color: $medium-gray;
  }

  &:disabled:hover {
    cursor: not-allowed;
    background-color: $gray95;
  }

  &.no-borders {
    border: none;

    &:hover {
      cursor: pointer;
      border-radius: 5px;
      background-color: $light-gray;
    }

    &:focus {
      cursor: inherit;
      border-radius: 5px;
      background-color: transparent;
      box-shadow: inset 0 0 0 2px $light-gray;
    }

    &:disabled,
    &[readOnly] {
      background-color: transparent;
      transition: background-color 0.25s ease-in-out;
    }

    &:disabled:hover {
      cursor: not-allowed;
      background-color: $gray95;
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

@import 'styles/colors';

.divider {
  min-height: 1px;
  height: 1px;
  width: 100%;
  background-color: $border-gray;

  &.dashed {
    background-color: transparent;
    border-bottom: 1px dashed $border-gray;
  }
}

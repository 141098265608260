@import 'styles/colors';

.tooltip-wrapper {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  display: none;
  align-items: center;
  opacity: 1;
  transition: opacity 0.2s;

  @media screen and (min-width: 64em) {
    display: flex;
  }

  &.hidden {
    opacity: 0;
  }

  .tooltip {
    z-index: 2;
    font-size: 12px;
    border-radius: 3px;
    position: relative;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    margin: 0 10px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -5px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 5px 4px 0;
    }

    &.light {
      color: $primary-dark-blue;
      background-color: white;
      box-shadow: 0 10px 18px rgba(0, 0, 0, 0.4);

      &::before {
        border-color: transparent white transparent transparent;
        box-shadow: 0 10px 18px rgba(0, 0, 0, 0.4);
      }
    }

    &.dark {
      color: white;
      background-color: rgba($primary-dark-blue, 0.9);
      box-shadow: 0 10px 18px rgba(0, 0, 0, 0.2);

      &::before {
        border-color: transparent rgba($primary-dark-blue, 0.9) transparent transparent;
        box-shadow: 0 10px 18px rgba(0, 0, 0, 0.2);
      }
    }

    &.right {
      &::before {
        left: 100%;
        border-width: 4px 0 4px 5px;
      }
    }

    &.right {
      &.light {
        &::before {
          border-color: transparent transparent transparent white;
        }
      }
      &.dark {
        &::before {
          border-color: transparent transparent transparent rgba($primary-dark-blue, 0.9);
        }
      }
    }
  }
}

.wrapper {
  display: flex;
}

.portal-tooltip {
  background-color: var(--color-dark-blue);
  color: white;
  position: relative;
  display: inline-block;
  padding: 5px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 5px;
  margin: 10px 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;

  &.small {
    font-size: 12px;
    font-weight: 600;
  }

  &.content-centered {
    text-align: center;
  }

  &.top,
  &.bottom {
    opacity: 1;
    visibility: visible;

    .triangle {
      position: absolute;
      left: 50%;
      width: 20px;
      height: 12px;
      background-color: var(--color-dark-blue);
    }
  }

  &.bottom {
    .triangle {
      top: 0;
      transform: translate(-50%, -50%);
      clip-path: polygon(50% 0, 100% 100%, 0 100%);
    }
  }

  &.top {
    .triangle {
      bottom: 0;
      transform: translate(-50%, 50%);
      clip-path: polygon(0 0, 100% 0, 50% 100%);
    }
  }
}

.tooltip2 {
  display: inline-flex;
}

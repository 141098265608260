.stats-body {
  display: flex;

  .block {
    padding: 0 5px;
    display: flex;
    align-items: center;
    height: 50px;
    min-width: 50px;
    position: relative;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid var(--color-alto);

    .value {
      font-weight: 600;
    }

    .label {
      margin-top: 2px;
      font-size: 7px;
      font-weight: 600;
      text-transform: uppercase;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@import 'styles/colors';
@import 'styles/variables';

.NotificationBody {
  &.hasTopDivider {
    border-top: 1px solid $medium-gray;
  }

  .content-block {
    display: flex;
    flex: 1;
    background-color: white;
    position: relative;
    padding: 15px;
    border-radius: 5px;

    .is-svg svg {
      width: 35px;
      max-height: 35px;
      margin-top: 10px;
    }
  }

  .notification-block {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 15px;
    font-size: 14px;

    .notification-caption {
      .color-primary {
        display: inline-block;
        color: $primary;
        padding: 0;
        font-size: 14px;
      }
    }

    .notification-content {
      color: $dark-gray;
      word-break: break-word;

      .diagnoses {
        padding: 5px 0;

        div {
          margin-bottom: 5px;
        }
      }

      a {
        display: inline-block;
        padding: 5px 0;
        color: $primary;
        font-weight: 400;
      }
    }

    .notification-footer {
      @media screen and (min-width: 48.325em) {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
      }

      .notification-time {
        color: $dark-gray;
      }

      .notification-actions {
        display: flex;
        color: $primary;

        @media screen and (max-width: 48em) {
          display: none;
        }

        a {
          display: inline-block;
        }

        span {
          cursor: pointer;
        }

        .primary-link {
          color: $primary;
          font-size: 14px;
          font-weight: 400;
          padding: 0;

          &:hover,
          &:active {
            color: $primary;
          }
        }
      }
    }
  }
  .mobile-actions {
    display: flex;

    @media screen and (min-width: 48.325em) {
      display: none;
    }

    .action-btn {
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: none;
      border-radius: 0;
      border-width: 1px;
      margin: 0 -1px -1px;

      &:first-child {
        border-bottom-left-radius: 5px;
        border-right: none;
      }

      &:last-child {
        border-bottom-right-radius: 5px;
      }
    }
  }
}

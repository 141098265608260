.nots-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .settings-link {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    color: var(--color-primary);
  }
}

.list-container {
  padding: 20px;

  &.no-notifications {
    height: 100%;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  min-height: 45px;

  &.actions {
    z-index: 4;
  }
}

.name-wrapper {
  font-size: 12px;
}

.name {
  font-weight: 600;
  align-items: center;
  display: flex;
  font-size: 14px;

  .icon {
    color: var(--color-gray);
    margin-left: 5px;
  }
}

.more-tools {
  border-radius: 5px;
  padding: 2px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.date-badge {
  background-color: var(--color-gray-light);
  font-weight: 600;
  border-radius: 3px;
  font-size: 10px;
  border: 1px solid var(--color-alto);
  padding: 0 4px;
}

.modal-body {
  display: flex;
  padding: 0 !important;
  flex-grow: 1;
  overflow: hidden;
}

.load-activity {
  width: 55%;
}

.tab-wrapper {
  width: 45%;
  border-left: 1px solid var(--color-alto);
}

.map {
  height: var(--height-map-modal);
}

.map-marker {
  border-radius: 50%;
  border: 2px solid white;
  background-color: var(--color-primary);
  width: 15px;
  height: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.marker {
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  position: relative;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 2px solid var(--color-black);
  background-color: white;
  width: 12px;
  height: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  &:before {
    content: '';
    width: 4px;
    height: 4px;
    left: 50%;
    top: 50%;
    background-color: var(--color-black);
    position: absolute;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
}

@import 'styles/variables';
@import 'styles/colors';

.activity-block {
  height: calc(100% - #{$modal-map-height});
  padding: 22px 15px 22px 25px;
}

.activity-header {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 15px;
}

.activity-title {
  font-size: 16px;
}

.activity-filter {
  font-size: 14px;
  font-weight: 600;
  color: #a3a3a3;
  align-items: center;
  display: flex;
  padding: 5px;
  background-color: transparent;

  svg {
    margin-left: 8px;
    transition: transform 0.25s ease-in-out;
    transform: rotateZ(-90deg);
  }

  &.opened {
    svg {
      transform: rotateZ(0deg);
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.activity-log-wrapper {
  overflow: auto;
  height: calc(100% - 20px);
  padding-bottom: 15px;
}

.activity-log-item {
  display: flex;

  &:not(:first-child) {
    margin-top: 25px;
  }
}

.activity-log-info {
  margin-left: 22px;
  line-height: 22px;
}

.activity-log-load,
.activity-log-name {
  font-weight: 600;
}

.activity-log-name {
  margin-right: 4px;
}

.activity-log-date {
  color: $dark-gray;
}

.loader {
  position: initial;
  width: 2.5em;
  height: 2.5em;
  border-width: 2px;
}

.capitalize {
  text-transform: capitalize;
}

$sizes: (
  tiny: 15px,
  small: 25px,
  medium: 30px,
  large: 40px,
);

$roundSizes: (
  tiny: 6px,
  small: 10px,
  medium: 15px,
  large: 20px,
);

@mixin set-badge-size($currentSize) {
  $size: map_get($sizes, $currentSize);
  $roundSizes: map_get($roundSizes, $currentSize);
  width: $size;
  height: $size;

  .round-icon {
    height: $roundSizes;
    width: $roundSizes;
  }
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  flex-shrink: 0;

  .round-icon {
    border-radius: 50%;
    background-color: white;
  }

  .periodic-icon {
    width: 55%;
    color: white;
  }

  &.brand-blue {
    background-color: var(--color-blue-brand);
  }

  &.blue {
    background-color: #00aeff;
  }

  &.orange {
    background-color: var(--color-primary);
  }

  &.yellow {
    background-color: var(--color-orange-peel);
  }

  &.large {
    @include set-badge-size('large');
  }

  &.medium {
    @include set-badge-size('medium');
  }

  &.small {
    @include set-badge-size('small');
  }

  &.tiny {
    @include set-badge-size('tiny');
  }
}

.star-badge {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fdd94b;
  color: white;
  padding: 2px;
}

@import 'styles/colors';

.layout {
  width: 100%;
  background-color: $secondary-dark-blue;
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .children-wrapper {
    background-color: white;
    width: 500px;
    margin: 0 auto;
    padding: 30px 55px 45px;
    transition: all 0.3s;
  }

  .everypig-logo {
    width: 170px;
    height: 120px;
    margin-bottom: 20px;
  }

  @media (max-width: 40em) {
    background-color: white;

    .children-wrapper {
      width: 100%;
      padding: 20px;
    }

    .everypig-logo {
      :global {
        .st1 {
          fill: #222944;
        }
      }
    }
  }

  .link-wrapper {
    display: flex;
    justify-content: center;
  }

  .privacy-policy {
    margin-top: 30px;
    font-size: 12px;
    color: $gray73;
    white-space: pre-line;
    text-align: center;
  }
}

@import 'styles/colors';

.container {
  background-color: $almost-white;
  border: 1px solid $border-gray;
  border-radius: 5px;
  padding: 0 10px;

  .header {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .notes {
    font-size: 14px;
    border: 2px solid $border-gray;
    min-height: 140px;
    padding: 5px;
    width: 100%;
    overflow: scroll;
    resize: none;
    font-family: inherit;
    margin: 10px 0;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;

    &:focus {
      border: 2px solid $primary;
      background-color: white;
      outline: none;
      box-shadow: 0 0 4px #ff8664;
    }
  }

  .btn {
    color: $primary;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &.hidden {
      opacity: 0;
    }
  }
}

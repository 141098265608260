@import 'styles/colors';

.number-input-wrapper {
  height: 40px;
  width: 65px;
  display: flex;
  border: 1px solid $light-gray;
  overflow: hidden;

  .number-input {
    border: none;
    box-shadow: none;
  }

  .input-btn-group {
    border-left: 1px solid $light-gray;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .icon-wrapper {
      cursor: pointer;
      height: 50%;
      min-width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $light-gray;
      }
    }

    .number-btn-icon {
      color: $bright-gray;
      width: 12px;
    }
  }
}

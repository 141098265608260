@import 'styles/colors';

.company-card {
  padding: 15px;
  border-radius: 8px;
  border: 1px solid $border-gray;

  .desc {
    margin-top: 2px;
    font-size: 12px;
  }
}

@import 'styles/colors';

.icon-column {
  width: 45px;
  min-width: 45px;
  text-align: center;

  &.warning {
    background-color: $brand-orange;
  }

  &.error {
    background-color: $punch;
  }

  &.success {
    background-color: $brand-blue;
  }

  .icon {
    width: 25px;
    height: 25px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: white;
  }
}

.content-column {
  margin: auto 0;
  padding: 10px;
  line-height: 1.4;
}

.in-app {
  display: flex;
  align-items: center;

  .icon-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 80px;

    &.is-not-svg {
      flex: 0 0 60px;
    }

    svg {
      width: 50px;
      min-width: 50px;
      height: auto;
    }
  }

  .text-block {
    margin-left: 10px;
    font-size: 13px;
  }
}

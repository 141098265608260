@import 'styles/colors';

.date-fields {
  display: flex;
  align-items: center;

  .time-format {
    cursor: pointer;
    font-size: 20px;
    color: $bright-gray;
    font-weight: 600;
    user-select: none;

    &.active {
      color: $primary;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.required-head-input {
  min-width: 40px;
}

// if you don't know how to name it, just use http://chir.ag/projects/name-that-color
$primary-dark-blue: #14182b;
$secondary-dark-blue: #1f273d;
$gray98: #fafafa;
$gray95: #f3f3f3;
$gray73: #bababa;
$light-gray: #e8e8e8;
$medium-gray: #cacaca;
$dark-gray: #a3a3a3;
$black: #303030;
$primary: #ff5e31;
$brand-blue: #0069a1;
$light-blue: #96adbd;
$light-orange: #fff5f0;
$light-yellow: #fffceb;
$bright-gray: #8c8c8c;
$border-gray: #ddd;
$almost-white: #f5f5f5;
$punch: #dc3522;
$purple: #cc66cb;
$brand-orange: #ffb200;
$alice-blue: #e3eff5;
$brand-green: #00a169;
$gallery: #ebebeb;
$french-rose: #f74d8e;
$cinnamon: #745902;
$supernova: #ffcd03;
$orange-peel: #ff9d00;
$mustard: #feda4b;
$energy-yellow: #ffdb4b;

$loadCardShadowColors: (
  $light-blue: rgba(150, 173, 189, 0.6),
  $brand-blue: rgba(0, 105, 161, 0.4),
  $primary: rgba(255, 94, 49, 0.4),
  $dark-gray: rgba(163, 163, 163, 0.5),
);

@import 'styles/colors';

$btn-height: 50px;

.sales-result-section {
  min-height: calc(100% - #{$btn-height});

  .section-row {
    width: 50%;
  }

  .submitted-user-row {
    margin: 10px 0 0 0;
    padding-bottom: 30px;
  }
}

.btn-section {
  margin-top: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;

  &.sticky {
    z-index: 1;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    margin-top: 0;
  }

  .btn {
    min-height: $btn-height;
    width: 295px;
    font-size: 16px;
    flex: 1;
    margin: 0 10px;
  }
}

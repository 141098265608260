.condition-input-row {
  .value-input {
    width: 90px;
    height: 30px;
    margin-bottom: 0;
    margin-right: 10px;
    display: inline-block;
    font-weight: normal;

    &.longer {
      width: 150px;
    }
  }
}

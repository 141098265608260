@import 'styles/colors';

.welcome-text {
  font-size: 30px;
  margin: 10px 0;
}

.welcome-subtext {
  font-size: 16px;
  margin-bottom: 30px;
}

.welcome-text,
.welcome-subtext {
  text-align: center;
}

.create-btn {
  width: 100%;
}

.company-item {
  position: relative;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid $light-gray;
  padding: 14px;
  margin-bottom: 15px;
  background-color: $almost-white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .avatar {
    border-radius: 50%;
    overflow: hidden;
    background-color: $gray73;
    color: white;
    margin-right: 15px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .name {
    flex: 1 1 auto;
    font-weight: 600;
  }

  .icon {
    margin-left: 20px;
    flex-shrink: 0;
    color: $gray73;
  }

  &:hover {
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(255, 94, 58) 3%, rgb(255, 140, 59) 103%);

    .avatar {
      background-color: $secondary-dark-blue;
    }

    .name,
    .icon {
      color: white;
    }
  }
}

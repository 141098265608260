.option,
.option-label {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: 0.2s;
  position: relative;

  .name {
    font-weight: 600;
    padding-left: 10px;
  }
}

.option {
  &:hover {
    background-color: #f2f7fa;
  }

  .selected-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    color: var(--color-blue-brand);
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
  }
}

.option-label {
  width: 100%;
  padding: 5px 0;
}

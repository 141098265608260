@import 'styles/colors';

.suggestion-box {
  padding: 10px;
  display: flex;
  align-items: center;
  border: 1px dashed var(--color-gray);
  border-radius: 3px;
  color: var(--color-gray);

  .icon {
    width: 23px;
    height: 23px;
  }

  .content {
    padding-left: 15px;
  }

  .btn {
    font-weight: 600;
    color: rgba($bright-gray, 0.6);
    margin-left: 10px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: var(--color-gray);
    }
  }

  &.disabled {
    position: relative;

    &::after {
      cursor: not-allowed;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($gray95, 0.4);
    }
  }
}

@import 'styles/colors';

.button {
  background-color: $light-gray;
  font-family: inherit;
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  line-height: 1;
  border-radius: 3px;
  vertical-align: middle;
  padding: 12px 15px;
  color: #000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 0;
  font-weight: 600;
  transition: all 0.25s;
  user-select: none;

  &.is-link {
    color: #000;
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &.is-link:hover {
    background-color: #c4c4c4;
    color: #000;
  }

  &.primary {
    color: white;
    background-image: linear-gradient(0deg, rgb(255, 94, 58) 3%, rgb(247, 132, 69) 103%);

    &:hover {
      color: white;
      background-image: linear-gradient(
        0deg,
        scale-color(rgb(255, 94, 58), $lightness: -15%) 3%,
        scale-color(rgb(247, 132, 69), $lightness: -15%) 103%
      );
    }

    &.shine {
      box-shadow: 0 0 6px 1px rgba(255, 94, 49, 0.8);
    }
  }

  &.full-width {
    width: 100%;
  }

  &.large {
    min-height: 55px;
    font-size: 20px;
  }

  &.medium {
    padding: 10px;
  }

  &.light {
    border: 1px solid $light-gray;
    background-color: transparent;
    background-image: linear-gradient(
      0deg,
      rgba(237, 235, 235, 0.68) 3%,
      rgba(255, 255, 255, 0.68) 103%
    );

    &:hover,
    &:active {
      background-image: linear-gradient(
        0deg,
        scale-color(rgb(237, 235, 235), $lightness: -5%) 13%,
        scale-color(rgb(255, 255, 255), $lightness: -5%) 93%
      );
    }

    &:focus {
      color: inherit;
    }
  }

  &.hollow {
    background-color: transparent;
    border: 1px solid $primary;
    color: $primary;
    transition: 0.2s;

    &:hover {
      background-color: $primary;
      color: white;
    }
  }

  &.transparent {
    background-color: transparent;
    color: $black;
    text-decoration: underline;
  }

  &.rounded {
    border-radius: 50px;
  }

  &.gray {
    border-color: $border-gray;
    background-image: linear-gradient(
      0deg,
      rgba(245, 245, 245, 0.68) 3%,
      rgba(255, 255, 255, 0.68) 100%
    );

    &.shine {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }

  &.dark-grey {
    color: white;
    background-color: #5e5e5e;

    &:hover,
    &:active {
      background-color: $black;
    }
  }

  &.punch {
    background-color: $punch;
    color: white;

    &.shine {
      box-shadow: 0 1px 9px rgba(220, 53, 34, 1);
    }
  }

  &.blue {
    background-color: $brand-blue;
    color: white;

    &.shine {
      box-shadow: 0 1px 9px rgba(0, 105, 161, 1);
    }
  }

  &.transparent-bordered {
    background: transparent;
    border: 1px solid $primary;
    color: $primary;
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
      background: transparent;
      color: scale-color($primary, $lightness: -30%);
      border-color: scale-color($primary, $lightness: -30%);
    }
  }

  &.blended {
    mix-blend-mode: screen;
    background-color: transparent;
    transition: 0.2s;
    color: white;
    border: 1px solid white;

    &:hover {
      color: black;
      background-color: white;
    }
  }

  &.decline {
    background-color: $punch;
    color: white;

    &:focus,
    &:hover {
      background-color: scale-color($punch, $lightness: -15%);
    }
  }

  &.disabled {
    opacity: 0.65;
    cursor: not-allowed;

    &:focus,
    &:hover {
      background-color: $medium-gray;
    }

    &.punch {
      background-color: $punch;
    }

    &.blue {
      background-color: $brand-blue;
    }

    &.transparent-bordered {
      background: transparent;
    }
  }
}

@import 'styles/colors';

.manage-table-columns {
  min-height: 100%;
  max-width: 620px;
  padding: 25px 0;
  margin: 0 auto;

  .search-section {
    position: relative;
    margin: 0 10px;
  }

  .search-input {
    height: 40px;
    min-height: 40px;
    padding: 0 40px;
    margin-bottom: 5px;
    border: 1px solid $border-gray;
    border-radius: 5px;
    font-size: 16px;
    color: #757575;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .search-icon {
    position: absolute;
    color: $dark-gray;
    left: 13px;
    top: 10px;
  }

  .clear-icon {
    position: absolute;
    color: $dark-gray;
    right: 13px;
    top: 12px;
    cursor: pointer;
  }

  .fields-title {
    margin: 25px 10px 0;
    font-size: 16px;
    display: flex;
    justify-content: space-between;

    .reset-btn {
      cursor: pointer;
      font-size: 14px;
      color: $bright-gray;
    }
  }

  .hidden-fields-title {
    position: relative;
    margin: 30px 10px 0;
    text-transform: uppercase;
    text-align: center;
    color: $dark-gray;

    hr {
      position: absolute;
      width: 100%;
      border-style: dashed;
      top: 2px;
      color: $border-gray;
    }

    .text {
      padding: 0 25px;
      background-color: white;
      position: relative;
      font-weight: 600;
    }
  }

  .default-field,
  .visible-field {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
    color: $black;
  }

  .default-field {
    margin: 10px 10px 0;
    padding: 0 20px;
    background-color: $almost-white;
  }

  .visible-field {
    margin: 10px 10px 0;
    padding: 0 20px 0 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    background-color: white;

    &.is-dragging .drag-icon {
      color: $primary;
    }

    &.is-not-matching-search {
      visibility: hidden;
      opacity: 0;
      height: 0;
      margin: 0;
    }

    .drag-icon {
      margin-right: 5px;
      color: $border-gray;
    }
  }

  .drop-zone {
    margin-top: 10px;
    padding-bottom: 10px;
    border: 2px dashed transparent;
    border-radius: 8px;

    &.is-empty {
      border-color: $border-gray;
      background-color: $almost-white;
      margin: 10px 10px 0;
    }

    &.is-dragging-over {
      background-color: rgba(255, 95, 51, 0.1);
      border-color: $primary;
      margin: 10px 0 0;
    }
  }

  .fields-status {
    margin-left: auto;
    font-size: 12px;
    color: $dark-gray;
  }

  .defaults-placeholder {
    margin-top: 10px;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
    border-radius: 8px;
    font-size: 13px;
    color: $dark-gray;
    text-align: center;
  }
}

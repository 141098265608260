@import 'styles/variables';

.modal-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: rgba(30, 38, 62, 0.7);
  overflow: scroll;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.is-visible {
    opacity: 1;
  }

  .animation-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.modal {
  max-width: 100%;
  width: 580px;
  background-color: white;
  border-radius: 5px;
  pointer-events: all;
  box-shadow: 3px 3px 10px rgba(51, 51, 51, 0.5);
  z-index: 2;
  display: flex;
  flex-direction: column;

  &.auto-width {
    width: auto;
  }

  @media screen and (max-width: 40em) {
    max-width: 98%;
    height: 98%;
    overflow: auto;
    margin-top: 1%;

    &.isMobileFullScreen {
      width: 100vw;
      height: 100vh;
      margin: 0;
      max-width: unset;
      border-radius: 0;
      font-size: 25px;

      .close-button {
        top: calc(10px + #{$notch-top});
        right: calc(10px + #{$notch-right});
      }
    }
  }
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 15px;
  margin: 0;
}

.modal-header {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-alto);
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  font-size: 18px;

  &.overflow-visible {
    overflow: visible;
  }
}

.modal-footer {
  background-color: var(--color-alabaster);
  border-top: 1px solid var(--color-alto);
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}

@supports (padding-top: env(safe-area-inset-top)) {
  .modal {
    max-width: calc(98% - #{$notch-top} - #{$notch-right});

    @media screen and (max-width: 40em) {
      height: calc(98% - #{$notch-top} - #{$notch-bottom});
      margin-top: calc(1% + #{$notch-top});
      width: calc(98% - #{$notch-top} - #{$notch-right});
    }
  }
}

.close-button {
  display: flex;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.2s;
  cursor: pointer;
  color: var(--color-black);

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@import 'styles/colors';

.time-picker {
  border-radius: 3px;
  align-items: center;
  display: flex;
  border: 1px solid $border-gray;
  position: relative;

  &.disabled {
    &::after {
      content: '';
      width: 100%;
      position: absolute;
      height: 100%;
      cursor: not-allowed;
      background-color: rgba(#f3f3f3, 0.4);
    }
  }

  .time-input {
    user-select: none;
    border: none;
    border-right: 1px solid $border-gray;
  }

  .time-format-picker {
    display: flex;
    min-width: 75px;
    height: 100%;
    user-select: none;

    .time-format-item-wrapper {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      color: $bright-gray;
      transition: 0.3s;

      &.active {
        background-color: $primary;
        color: white;
      }

      .time-format {
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
}

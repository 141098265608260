@import 'styles/colors';

.menu {
  position: absolute;
  z-index: 1;
  top: 100%;
  margin-top: 10px;
  left: 0;
  transition: opacity 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0 -1px 12px 0;
  background-color: white;
  padding-top: 15px;
  min-width: 230px;

  .menu-checkboxes-wrapper {
    padding: 0 10px;
  }

  .title {
    padding: 0 20px 10px;
    color: $black;
    font-size: 14px;

    &.top-padding {
      padding-top: 15px;
    }
  }

  .checkbox-item {
    padding: 9px 20px 0;
    display: block;
    height: 30px;

    & > div {
      padding-left: 16px;
    }
  }

  .checkbox-label {
    opacity: 0.5;
    font-size: 14px;
    color: $black;
    font-weight: 600;
    padding-left: 5px;

    &.checked {
      opacity: 1;
    }
  }

  .day-picker-line {
    font-size: 12px;
    border-radius: 5px;
    padding: 10px 20px 0;

    .nav-icon {
      width: 12px;
      color: $black;
      padding-top: 2px;
    }

    :global {
      .CalendarMonth_table tbody {
        border: none;
      }

      .CalendarDay__default {
        border: none;
        padding: 0;
        border-radius: 50%;
        font-size: 12px;
        line-height: 29px; // for vertical centering, size 30x29 when daySize = 30
      }

      .CalendarDay__selected,
      .CalendarDay__selected_span,
      .CalendarDay__selected:active,
      .CalendarDay__selected:hover {
        background: $primary;
        color: white;
      }

      .CalendarDay__hovered_span {
        background: #ffe8df;
        color: $black;
      }

      .CalendarMonth_caption {
        font-size: 14px;
        color: $primary;
        padding-top: 10px;
      }

      .DayPicker_weekHeader {
        color: $black;
        top: 40px;
        opacity: 0.5;
      }

      .DayPickerNavigation {
        display: flex;
        justify-content: space-between;
        padding: 0 30px;

        &_button__horizontal {
          padding-top: 9px;
        }
      }

      .DayPickerKeyboardShortcuts_show {
        display: none;
      }
    }
  }

  .selected-item {
    margin: 0 10px 10px 0;
    background-color: $primary;
    padding: 6px;
    color: white;
    font-size: 12px;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
  }

  .value-input-line {
    padding: 10px;
    font-size: 14px;
    color: $black;
    display: flex;
    align-items: baseline;
    font-weight: normal;
  }

  .value-input {
    width: 90px;
    height: 30px;
    margin-bottom: 0;
    margin-right: 10px;
    display: inline-block;
    font-weight: normal;

    &.longer {
      width: 150px;
    }
  }

  .from-or-to {
    flex: 1;
    opacity: 0.5;
    color: $black;
  }

  .value-label {
    font-weight: normal;
  }

  .done-button {
    margin-top: 10px;
    text-align: center;
    height: 40px;
    border: none;
    border-top: 1px solid $light-gray;
    width: 100%;
    color: $primary;
    font-weight: 600;
    font-size: 13px;
    border-radius: 0 0 3px 3px;
    cursor: pointer;
    background-color: white;

    &:focus {
      outline: none;
    }
  }
}

@import 'styles/colors';

// override for src/components/SingleDatePicker.scss
.date-time-picker {
  .SingleDatePickerWrapper {
    border: 1px solid $border-gray;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    .picker-label {
      &.left {
        border-right: 1px solid $border-gray;
      }
    }

    .picker-icon {
      width: 16px;
      height: 16px;
    }
  }
}

@import 'styles/colors';

.contract-wrapper {
  padding: 0 30px;
}

.contract-location {
  font-size: 12px;
  margin-bottom: 20px;
}

.contract-name {
  font-size: 16px;
  font-weight: 600;

  &-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.contract-information {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;

  svg {
    margin-right: 15px;
  }
}

.stats-info {
  display: flex;
  width: 100%;
  padding: 10px 0;
}

.contract-title {
  width: 40%;
  font-weight: 600;
}

.star-icon {
  width: 25px;
  height: 25px;
  margin: 0;
  color: $mustard;
}

.special-contract-sections {
  border-top: 1px solid $border-gray;
  margin-top: 20px;
  padding-top: 30px;

  .special-contract-checkbox > div:first-child {
    cursor: default;
  }
}

.sections-list {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}

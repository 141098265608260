@import 'styles/colors';

.section-wrapper {
  margin-bottom: 18px;

  .title {
    font-size: 14px;
    text-transform: uppercase;
    color: $black;
    font-weight: 600;
    opacity: 0.6;
  }

  .wrapper-content {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}

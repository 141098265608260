.badge {
  display: inline-block;
  min-width: 20px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 10px;
  padding: 2px 4px;
  font-weight: 600;
  text-align: center;
  background: var(--color-silver-chalice);
  color: white;
}

@import 'styles/colors';

.tab-holder {
  .tabs-navbar-container {
    border-bottom: 1px solid $border-gray;
  }

  .tab-content {
    position: relative;
    overflow: auto;
    height: 100%;
    padding: 25px 0;

    &.has-navbar {
      height: calc(100% - 40px);
    }
  }
}

.highlighter {
  background-color: rgba(246, 218, 88, 0.08);
  border-left: 4px solid #f6da58;
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.wrapper {
  position: relative;
}

@import 'styles/colors';

.wrapper {
  display: flex;
  align-items: stretch;
  position: relative;

  .content {
    width: 100%;
  }

  .marker-wrapper {
    display: flex;
    height: 25px;

    &.is-not-end {
      &:after {
        content: '';
        background-repeat: no-repeat round;
        background-position-x: center;
        background-image: url('./../../icons/circle.svg');
        height: calc(100% - 30px);
        position: absolute;
        top: 25px;
        width: 20px;
      }

      .marker {
        color: $border-gray;
      }
    }

    &.is-dest-marker {
      &:after {
        height: calc(100% - 35px);
        top: 30px;
      }
    }

    .marker {
      text-align: center;
      width: 20px;
      margin-right: 15px;
      background-color: white;
      color: $primary;

      &.source {
        border: 4px solid $border-gray;
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }
    }
  }
}

@import 'styles/colors';

$fontSizes: (
  small: 10px,
  medium: 12px,
  large: 14px,
);
$avatarSizes: (
  small: 25px,
  medium: 35px,
  large: 40px,
);

@mixin set-size($currentSize) {
  $size: map_get($avatarSizes, $currentSize);
  $fontSize: map_get($fontSizes, $currentSize);

  .image,
  .upload-btn,
  .letters {
    width: $size;
    height: $size;
    line-height: $size;
    font-size: $fontSize;
  }
}

.avatar {
  text-align: center;
  display: inline-block;
  position: relative;

  &.small {
    @include set-size('small');
  }

  &.medium {
    @include set-size('medium');
  }

  &.large {
    @include set-size('large');
  }

  .image,
  .letters {
    background: $gray73;
    border-radius: 50%;
    overflow: hidden;
  }

  .image {
    vertical-align: bottom;
    display: inline-block;
  }

  .letters {
    color: white;
    text-transform: uppercase;
  }
}

.content {
  overflow: auto;
  padding: 55px 30px 30px;
  width: 100%;
  height: 100%;

  .back-icon {
    width: 10px;
    position: absolute;
    top: 15px;
    cursor: pointer;
  }
}

.btn-section {
  z-index: 1;
  position: sticky;
  position: -webkit-sticky;
  margin: 20px -10px 0;
  bottom: 0;
  display: flex;
  align-items: center;

  .submit-btn {
    flex: 1;
    margin: 0 10px;
    height: 50px;
    font-size: 16px;
  }

  .white {
    border: 1px solid var(--color-alto);
    background-color: white;
    box-shadow: 0 4px 18px rgb(221, 221, 221);

    &:hover {
      background-color: var(--color-gray-light);
    }
  }
}

.select-placeholder {
  display: flex;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;
  color: var(--color-primary);
}

.edit-btn {
  font-size: 16px;
  color: var(--color-primary);
  cursor: pointer;
}

.actions {
  text-align: right;
  margin-bottom: 10px;
}

.commitment-timeline {
  .name {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
  }

  .label {
    font-size: 14px;
    color: var(--color-gray);
  }
}

.commitment-timeline.is-loading,
.is-loading {
  opacity: 0.7;
}

.trucking-section-title {
  display: flex;
  align-items: center;

  .edit-btn {
    margin-left: auto;
  }
}

.arrows-buttons {
  display: flex;
  flex-direction: column;
  user-select: none;

  .icon {
    padding: 5px;
    width: 25px;
  }
}

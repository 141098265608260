.dropdown-wrapper {
  position: relative;
  color: var(--color-primary);
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;

  .list-ul {
    position: absolute;
    z-index: 1;
    top: 100%;
    margin-top: 5px;
    left: 0;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    transition: opacity 0.2s;
    background-color: white;
    border: 1px solid var(--color-gray-light);
    text-align: left;
    min-width: 210px;
    overflow: hidden;

    &.is-opened-left {
      left: auto;
      right: 0;
    }

    .list-li {
      display: flex;
      align-items: center;
      width: 100%;
      font-weight: 400;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background-color: var(--color-concrete);
      }

      &:focus {
        outline: none;
      }

      .label {
        padding: 0 10px;
        color: var(--color-black);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .list-li:not(:last-child) {
      border-bottom: 1px solid var(--color-gray-light);
    }
  }
}

.tooltip {
  justify-content: center;
}

@import 'styles/colors';

.section-wrapper {
  position: relative;
  height: 35px;
  border: 1px solid $light-gray;
  border-radius: 3px;
  background-color: $light-gray;
  font-size: 13px;
  font-weight: 600;
  margin: 5px 10px 5px 0;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s ease;

  &.is-opened {
    border-color: $primary;
    color: $primary;
    background-color: $light-orange;
  }

  &.is-empty-value {
    border-color: $brand-orange;
    color: $brand-orange;
    background-color: $light-yellow;
  }

  &.is-disabled {
    background-color: $gray98;
    font-size: 13px;
    color: $dark-gray;
    cursor: default;
  }

  .search-label {
    padding: 0 10px;
    flex: 1;
  }

  .filter-icon {
    width: 16px;
  }

  .remove-icon {
    width: 10px;
    transition: opacity 0.2s ease;
    visibility: hidden;
    opacity: 0;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  &:hover {
    .remove-icon {
      visibility: visible;
      opacity: 1;
    }
  }
}

@import 'styles/colors';

.react-select {
  &.without-borders {
    .select__control {
      border: 2px solid transparent;
      min-height: 40px;

      &:hover {
        background-color: $light-gray;
        border: 2px solid transparent;
      }

      &--is-focused {
        border-color: $primary;

        &:hover {
          background-color: white;
          border-color: $primary;
        }
      }

      .dropdown-icon {
        display: none;
      }
    }
  }

  &.is-small {
    .select__control {
      min-height: 30px;
    }

    .dropdown-icon {
      height: 30px;
      width: 30px;
      padding: 6px;
    }

    .select__menu {
      &-list {
        max-height: 175px;
      }

      .select__option {
        padding: 8px 15px;
        font-size: 13px;
      }
    }

    .selected-icon {
      height: 14px;
    }
  }

  .select__control {
    border: 2px solid $border-gray;

    &:hover {
      border-color: $border-gray;
      cursor: pointer;
    }

    &--is-disabled,
    &--is-disabled:hover {
      cursor: not-allowed;
    }

    &--is-focused,
    &--is-focused:hover {
      border-color: $primary;
      box-shadow: none;
      cursor: pointer;

      .dropdown-icon {
        border-color: $primary;
      }
    }
  }

  .dropdown-icon {
    color: $primary;
    width: 40px;
    height: 36px;
    padding: 12px;
    border-left: 2px solid $border-gray;
  }

  .select__control,
  .dropdown-icon {
    transition: border-color 0.25s ease-in-out;
  }

  .select__menu {
    overflow: hidden;
    margin-bottom: 5px;
    margin-top: 5px;
    z-index: 2;

    &-list {
      padding: 0;
      max-height: 280px;
    }

    .select__option {
      border-bottom: 1px solid $light-gray;
      padding: 10px 15px;
      font-size: 14px;
      color: #000;
      cursor: pointer;

      &--is-focused {
        background-color: #ebf5ff;
      }

      &--is-selected {
        background-color: #f2f7fa;
      }
    }
  }

  .select-option-wrapper {
    position: relative;
  }

  .selected-icon {
    position: absolute;
    height: 20px;
    top: 10px;
    right: 10px;
    color: $brand-blue;
  }

  &.select--is-disabled {
    pointer-events: all;
  }
}

.IconSelectWrapper {
  .option,
  .option-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    position: relative;
  }

  .option-label {
    .label-icon {
      width: 20px;
      height: 20px;
      color: $primary;
    }

    .name {
      font-weight: 600;
      padding-left: 15px;
    }
  }

  .option {
    padding: 10px 0;
    border-bottom: 1px solid $almost-white;

    .name {
      font-weight: 600;
      padding-left: 15px;
    }

    &:hover {
      background-color: #f2f7fa;
    }

    .selected-icon {
      position: absolute;
      right: 15px;
      top: 50%;
      color: $brand-blue;
      width: 15px;
      height: 15px;
      transform: translateY(-50%);
    }
  }

  .custom-placeholder {
    display: flex;
    align-items: center;

    .placeholder-icon {
      margin-right: 15px;
      width: 20px;
      color: $primary;
    }
  }
}

@import 'styles/colors';

.marker {
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  position: relative;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 2px solid $black;
  background-color: white;
  width: 12px;
  height: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  &.dot:before {
    content: '';
    width: 4px;
    height: 4px;
    left: 50%;
    top: 50%;
    background-color: $black;
    position: absolute;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  .hint {
    display: flex;
    height: 60px;
    min-height: 60px;
    border-radius: 5px;
    background-color: white;
    font-size: 14px;
    position: absolute;
    top: -5px;
    transform: translate(-50%, -100%);
    overflow: hidden;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2);

    .distance-block {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      min-width: 60px;
      padding: 10px 5px;
      font-weight: 600;
      color: white;

      &.purple {
        background-color: $purple;
      }

      &.primary {
        background-color: $primary;
      }

      &.light-blue {
        background-color: $light-blue;
      }

      &.brand-blue {
        background-color: $brand-blue;
      }

      .value {
        font-size: 24px;
      }

      .label {
        font-size: 11px;
      }
    }

    .company-info-block {
      min-width: 150px;
      color: $black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;

      .name {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.4;
        white-space: nowrap;
      }

      .label {
        font-size: 13px;
      }
    }
  }
}

.confirm-modal {
  font-size: 16px;
  min-width: 320px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 3px 3px 10px rgba(51, 51, 51, 0.5);

  .confirm-text {
    margin: 15px 0;
    text-align: center;
  }
}

@import 'styles/colors';

.navbar-container {
  padding: 0 20px;

  &.sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
    background-color: white;
  }

  .navbar-section {
    z-index: 1;
    border-bottom: 1px solid $border-gray;
    background-color: white;

    &.unbordered {
      border-bottom: none;
    }

    .navbar-item {
      margin-right: 14px;
      height: 45px;
      font-size: 13px;
      display: inline-block;
      text-align: center;
      font-weight: 600;
      color: $dark-gray;
      padding: 0 4px;
      line-height: 45px;
      cursor: pointer;
      transition: all 0.25s;

      &.active {
        pointer-events: none;
        color: $primary;
        border-bottom: 3px solid $primary;
      }

      &:hover {
        color: $primary;
      }
    }
  }

  @media screen and (max-width: 32em) {
    padding: 0;

    .navbar-section {
      display: flex;
      border-bottom: none;
      box-shadow: 0 1px 11px rgba(0, 0, 0, 0.06);

      .navbar-item {
        flex: 1;
        margin: 0;

        &.active {
          border-width: 4px;
        }
      }
    }
  }
}

@import 'styles/colors';

$background: #f6f7f8;
$grey-nuance-lighter: #eeeeee;

.wrapper {
  position: relative;
  min-height: 400px;
  width: 100%;
  height: 100%;

  &.small {
    min-height: 180px;
  }
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  text-align: center;
  color: $black;

  .round {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: $almost-white;
    position: relative;

    &.light-gray {
      background-color: $light-gray;
    }

    &.almost-white {
      background-color: $almost-white;
    }
  }

  .placeholder-icon {
    width: 200px;
    height: 200px;
  }

  .small {
    width: 100px;
    height: 100px;
  }

  .desc {
    padding-top: 8px;
  }

  .title {
    padding-top: 22px;
    font-weight: 600;
  }
}

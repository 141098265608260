.trucking-company-logo {
  border-radius: 50%;
  background-color: var(--color-primary);
  color: white;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 10px;
}

.trucking-company-title,
.trucking-company-name {
  font-weight: 600;
}

.trucking-company-name {
  margin: 16px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.load-notes {
  font-weight: 600;
  margin: 16px 0 10px;
}

.load-notes-body {
  line-height: 22px;
}

.stats-blocks {
  margin-top: 14px;
  line-height: normal;
}

@import 'styles/colors';

.dots-icon {
  color: $dark-gray;
  cursor: pointer;
  margin: auto;
  display: block;

  &.rotated {
    transform: rotate(90deg);
  }
}

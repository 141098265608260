.radio-button {
  display: flex;
  align-items: center;
  height: 40px;
  position: relative;

  &.disabled {
    opacity: 0.8;
    cursor: not-allowed;

    .label {
      cursor: not-allowed;

      &:before,
      &:after {
        cursor: not-allowed;
      }
    }
  }

  &.checked {
    .input + div:before {
      border-color: var(--color-primary);
    }

    .input + div:after {
      transform: scale(1);
    }
  }

  .input {
    display: none;
  }

  .label {
    cursor: pointer;
    padding-left: 40px;
    min-height: 22px;

    &:before,
    &:after {
      position: absolute;
      cursor: pointer;
      content: '';
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 8px;
      top: 8px;
      width: 18px;
      height: 18px;
      border: 2px solid var(--color-gray-light);
    }

    &:after {
      top: 13px;
      left: 13px;
      width: 12px;
      height: 12px;
      transform: scale(0);
      background-color: var(--color-primary);
    }
  }
}

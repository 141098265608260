@import 'styles/colors';

@mixin set-size($size) {
  width: $size;
  height: $size;
  line-height: $size;
  min-width: $size;
}

.tenant-avatar {
  @include set-size(35px);
  border-radius: 50%;
  background-color: $dark-gray;
  color: white;
  text-align: center;
  font-size: 14px;

  &.small {
    @include set-size(18px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
  }

  &.medium {
    @include set-size(25px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  &.highlighted {
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(255, 94, 58) 3%, rgb(255, 140, 59) 103%);
  }
}

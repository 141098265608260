.load-name,
.commitment-name {
  font-weight: 500;
  color: var(--color-primary);
  font-size: 13px;
  background-color: transparent;
  padding: 8px 0;

  &:hover {
    background-color: transparent;
    color: var(--color-primary);
  }

  &.color-black,
  &.color-black:hover {
    color: var(--color-black);
  }
}

.icon {
  height: 20px;
  margin: 0 5px;
  color: var(--color-gray);
}

.user-name-column {
  display: flex;
  align-items: center;
  color: var(--color-primary);
}

.contract-type-column {
  display: flex;
  align-items: center;
}

.results-load-name {
  color: var(--color-primary);
  padding: 10px 0;
}

.load-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1042px;
  max-height: 840px;
  height: 100%;
}

.commitment-modal {
  width: 100%;
  max-width: 1042px;
  max-height: 625px;
  height: 100%;
}

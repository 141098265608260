.wrapper {
  display: flex;
  align-items: center;

  &.actions {
    z-index: 4;
  }
}

.name-wrapper {
  font-size: 12px;
}

.name {
  font-weight: 600;
  align-items: center;
  display: flex;
  font-size: 14px;

  div {
    margin-left: 8px;
  }
}

.more-tools {
  border-radius: 5px;
  padding: 2px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.date-badge {
  background-color: var(--color-gray-light);
  font-weight: 600;
  border-radius: 3px;
  font-size: 10px;
  border: 1px solid var(--color-alto);
  padding: 0 4px;
}

.modal-body {
  display: flex;
  padding: 0 !important;
  height: calc(100% - 67px);
  overflow: hidden;
}

.map {
  width: 55%;
  height: 100%;
  padding: 10px;
}

.header {
  border: none;

  & > div {
    margin-left: auto;
  }
}

.avatar-label-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  background-color: rgba(black, 0.5);
  border-radius: 50%;
  opacity: 0;
  transition: 0.2s ease-in;

  &:hover {
    opacity: 1;
  }

  .camera-icon {
    color: white;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .upload-input {
    display: none;
  }
}

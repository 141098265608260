.wrapper {
  position: relative;

  .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: var(--color-silver-chalice);
    font-weight: normal;
    display: inline-flex;
    pointer-events: none;
  }

  .icon-input {
    padding-left: 45px;
  }

  &.icon-right {
    .icon {
      left: auto;
      right: 10px;
    }

    .icon-input {
      padding-left: 0;
      padding-right: 45px;
    }
  }
}

@import 'styles/colors';

.link {
  text-decoration: none;

  &.primary,
  &.primary:active,
  &.primary:hover {
    color: $primary;
  }

  &.white,
  &.white:active,
  &.white:hover {
    color: white;
  }

  &:active,
  &:hover {
    color: $primary;
    text-decoration: none;
  }

  &.underlined {
    text-decoration: underline;
    font-style: normal;

    &.hover {
      text-decoration: underline;
    }
  }
}

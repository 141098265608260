@import 'styles/colors';

.profile-status {
  display: inline-block;
  vertical-align: middle;
  padding: 2px 4px;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 600;
  font-size: 10px;
  color: white;

  &.accepted {
    background-color: $alice-blue;
    color: $brand-blue;
  }

  &.request_close,
  &.active,
  &.used,
  &.opened,
  &.confirmed,
  &.delivered {
    background-color: $brand-blue;
  }

  &.m,
  &.incomplete,
  &.awaiting_delivery,
  &.scheduled_delivery {
    background-color: $brand-orange;
  }

  &.pending {
    background-color: $energy-yellow;
    color: #745902;
  }

  &.disabled,
  &.closed {
    background-color: $dark-gray;
  }

  &.draft,
  &.matched {
    background-color: $gray98;
    color: $dark-gray;
  }

  &.processing {
    background-color: $almost-white;
    color: $dark-gray;
  }

  &.in_progress {
    background-color: #00aeff;
  }

  &.pending_close,
  &.action_required,
  &.conflict {
    background-color: $punch;
  }

  &.declined,
  &.canceled {
    color: $punch;
    background-color: transparent;
    border: 1px solid $punch;
  }
}

@import 'styles/colors';

.contract-wrapper {
  flex: 1 1;
  padding: 30px;
  overflow: auto;
  position: relative;

  &.is-loading {
    opacity: 0.7;
  }

  .buttons {
    margin-top: 10px;
    text-align: right;
  }
}

.contract-location {
  font-size: 12px;
  margin-bottom: 20px;
}

.contract-name {
  font-size: 16px;
  font-weight: 600;
}

.stats-info {
  display: flex;
  width: 100%;
  padding: 10px 0;
}

.contract-title {
  width: 40%;
  font-weight: 600;
}

.star-icon {
  width: 25px;
  height: 25px;
  margin: 0;
  color: $mustard;
}

.special-contract-sections {
  border-top: 1px solid $border-gray;
  margin-top: 20px;
  padding-top: 30px;

  .special-contract-checkbox > div:first-child {
    cursor: default;
  }
}

.sections-list {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}

@import 'styles/colors';

.range {
  width: 100%;
  height: 20px;

  .input-wrapper {
    position: relative;
    height: 25px;
    width: 100%;

    .input {
      z-index: 1;
      background-color: transparent;
      appearance: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 5px;

      &:focus {
        outline: none;
      }
    }

    .input::-webkit-slider-thumb {
      cursor: pointer;
      appearance: none;
      margin: 0;
      height: 20px;
      width: 20px;
      background: #dadada;
      border-radius: 100%;
      border: 0;
      transition: background-color 150ms;
    }

    .input::-moz-range-thumb {
      cursor: pointer;
      appearance: none;
      margin: 0;
      height: 20px;
      width: 20px;
      background: #dadada;
      border-radius: 100%;
      border: 0;
      transition: background-color 150ms;
    }

    .track {
      left: 2px;
      z-index: 1;
      height: 4px;
      top: 50%;
      width: 50%;
      background-color: $primary;
      position: absolute;

      &.empty {
        z-index: 0;
        width: 100%;
        background-color: white;
      }
    }
  }
}

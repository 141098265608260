@import 'styles/colors';

.DatePickerLabel {
  margin-bottom: 10px;
  font-weight: 600;
}

.SingleDatePickerWrapper {
  display: flex;
  cursor: pointer;
  border: 2px solid $border-gray;
  position: relative;
  border-radius: 3px;
  height: 40px;
  box-sizing: content-box;
  transition: 0.2s;
  min-width: 140px;

  .picker-label {
    cursor: pointer;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid $border-gray;
    height: 100%;
    transition: 0.2s;

    &.left {
      border-left: none;
      border-right: 2px solid $border-gray;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  &.no-border {
    border-color: transparent;

    &.focused {
      border-color: $gray95;

      .picker-label {
        border-color: $gray95;
      }
    }

    .picker-label {
      border-color: transparent;
    }

    &:hover {
      background-color: $gray95;
    }
  }

  &.focused {
    border-color: $primary;

    .picker-label {
      border-left-color: $primary;
    }
  }

  &.disabled {
    background-color: $gray95;
  }

  .SingleDatePicker {
    width: 100%;
    height: 100%;
  }

  .SingleDatePicker_picker {
    z-index: 2;
  }

  .DateInput_fang {
    z-index: 3;
    top: 56px !important;
  }

  .picker-icon {
    width: 20px;
    height: 20px;
    color: $primary;
  }

  .DayPicker {
    &_weekHeader {
      font-size: 14px;
      color: $black;
      font-weight: 600;
    }
  }

  .CalendarDay {
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: #ffd2ba;
      color: white;
    }

    &__blocked_out_of_range {
      color: $border-gray;
      background-color: $gray98;

      &:hover {
        color: $border-gray;
        cursor: auto;
        background-color: $gray98;
      }
    }

    &__selected {
      cursor: auto;
      color: white;
      background-color: $primary;

      &:hover {
        background-color: $primary;
        border: none;
      }
    }

    border: 1px solid $border-gray;
  }

  .DayPickerNavigation {
    &_button {
      border: 1px solid $border-gray;
      width: 40px;
      outline: none;
      height: 40px;

      .DayPickerNavigation_svg__horizontal {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &:focus {
        background-color: white;
      }

      &:hover {
        background-color: $primary;
        border-color: $primary;

        .DayPickerNavigation_svg__horizontal {
          fill: white;
        }
      }
    }
  }

  .SingleDatePickerInput {
    width: 100%;
    border: none;
    background: transparent;
  }

  .DateInput {
    width: 100%;
    min-width: 100px;
    background: transparent;
    position: relative;

    &_input {
      background: transparent;
      font-size: 14px;
      font-weight: 600;
      height: 100%;
      border-bottom: none;
      outline: none;

      &__readOnly {
        cursor: pointer;
      }

      &__disabled {
        cursor: not-allowed;
        background-color: $gray95;
        font-style: normal;
      }
    }
  }
}

@import 'styles/colors';

$fontSizes: (
  small: 11px,
  medium: 14px,
  large: 16px,
);
$avatarSizes: (
  small: 25px,
  medium: 35px,
  large: 40px,
);

@mixin set-size($currentSize) {
  $size: map_get($avatarSizes, $currentSize);
  $fontSize: map_get($fontSizes, $currentSize);
  width: $size;
  height: $size;
  font-size: $fontSize;
}

.avatar {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: white;
  background-image: linear-gradient(0deg, #ff5e3a 3%, #f78445 100%);

  &.small {
    @include set-size('small');
  }

  &.medium {
    @include set-size('medium');
  }

  &.large {
    @include set-size('large');
  }
}

@import 'styles/colors';

.existing-group-content {
  margin-left: 40px;
  height: 40px;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    color: $primary;
  }
}

.radio-content {
  margin-left: 40px;
}

.loader {
  $size: 50px;
  $smallSize: 30px;

  position: absolute;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%) rotate(0deg);
  margin: 0 auto;
  border: 4px solid rgba(255, 94, 49, 0.2);
  border-left: 4px solid var(--color-primary);
  border-radius: 50%;
  width: $size;
  height: $size;
  font-size: 10px;
  will-change: transform, opacity;
  pointer-events: none;
  display: none;

  &.active {
    display: block;
    animation: preloader 1.1s infinite linear;
  }

  &.centered {
    top: calc(50% - #{$size / 2});
  }

  &.small {
    width: $smallSize;
    height: $smallSize;

    &.centered {
      top: calc(50% - #{$smallSize / 2});
    }
  }
}

@keyframes preloader {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}

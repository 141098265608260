.sales-result-edit {
  position: relative;
  height: 100%;

  &.is-loading {
    opacity: 0.7;
  }

  .section-row {
    width: 50%;
  }

  .lock-btn {
    display: flex;
    margin: 0 auto 10px;
  }

  .icon,
  .unlock-icon {
    height: 16px;
    margin-right: 10px;
  }

  .unlock-icon {
    width: 16px;
  }
}
